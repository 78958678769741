/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@2.6.11/dist/vue.min.js
 * - /npm/noty@3.2.0-beta/lib/noty.min.js
 * - /npm/leaflet@1.6.0/dist/leaflet.min.js
 * - /npm/vue2-leaflet@2.5.2/dist/vue2-leaflet.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
